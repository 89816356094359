
import { defineComponent } from "vue";
import EventDateTip from "./EventDateTip.vue";
import dayjs from "dayjs";

export default defineComponent({
  mounted() {
    if (this.eventDateTimeGood) {
      this.$emit("enable-next");
    }
  },
  data() {
    const rightNow = Date.now();
    const A_DAY_IN_MS = 86400000;
    return {
      A_DAY_IN_MS,
      rightNow,
      choiceVoteEndDateTime: "" as string,
      restaurantVoteEndDateTime: "" as string,
      flow: ["calendar", "time"] as Array<string>,
    };
  },
  methods: { dayjs },
  computed: {
    eventDateTimeGood() {
      return new Date(this.rightNow) < new Date(this.eventStartDateTime);
    },
    formattedEventStartDateTime() {
      return this.eventDateTimeGood
        ? this.dayjs(this.eventStartDateTime).fromNow()
        : "";
    },
    eventStartDateTime: {
      get() {
        return this.$store.state.eventWizardContext.eventStartDateTime;
      },
      set(time) {
        this.$store.commit("setEventWizardEventStartDateTimeContext", time);
      },
    },
  },
  watch: {
    eventDateTimeGood: {
      handler(n, o) {
        if (n) {
          this.$emit("enable-next");
        } else {
          this.$emit("disable-next");
        }
      },
    },
  },
  components: {
    EventDateTip,
  },
});
