import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.shouldShow)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(_ctx.tipTextColor)
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.tipValidatorClass)
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(_ctx.tip), 1)
      ], 2))
    : _createCommentVNode("", true)
}