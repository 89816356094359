
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    tip: String,
    isValid: Boolean,
    showOnlyProblems: Boolean,
  },
  computed: {
    shouldShow(): boolean {
      return this.showOnlyProblems ? !this.isValid : true;
    },
    tipValidatorClass(): string {
      return this.isValid ? "bi bi-check" : "bi bi-x";
    },
    tipTextColor(): string {
      return this.isValid ? "text-limey" : "text-danger";
    },
  },
});
